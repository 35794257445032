export default {
  AdditionalBox: {
    key: 'additional-box',
    value: 'Additional box',
    canCreate: false,
  },
  BoxCancellation: {
    key: 'box-cancellation',
    value: 'Box cancellation',
    canCreate: false,
  },
  Delivery: {
    key: 'change-delivery',
    value: 'Change delivery',
    canCreate: true,
  },
  QcCheck: {
    key: 'qc-check',
    value: 'QC check',
    canCreate: false,
  },
  OrderUpdate: {
    key: 'order-update',
    value: 'Order update',
    canCreate: false,
  },
  Other: {
    key: 'other',
    value: 'Other',
    canCreate: true,
  },
  SpecialDelivery: {
    key: 'special-delivery',
    value: 'Special delivery',
    canCreate: false,
  },
};
