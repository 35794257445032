<template>
  <b-modal
    v-model="safeShow"
    size="lg"
    title="Change request"
    id="modal-change-request"
    @hide="resetData"
  >
    <template slot="modal-header-close"><wbr/></template>

    <form
      ref="changeRequestForm"
      id="changeRequestForm"
      @submit.prevent="submit"
    >
      <dimmer :active="loading">
        <!-- Change type dropdown -->
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="form-label">
                Change type <span class="text-danger">*</span>
              </label>
              <div class="d-flex">
                <select
                  v-model="changeType"
                  data-test="select-change-type"
                  class="form-control custom-select col-8"
                  required
                >
                  <option value="" disabled>Select a type</option>
                  <template
                    v-for="item in OrderChangeRequestTypes"
                  >
                    <option
                      v-if="item.canCreate"
                      :key="item.key"
                      :value="item.key"
                    >
                      {{item.value}}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Change delivery section -->
        <div
          v-if="changeType === OrderChangeRequestTypes.Delivery.key"
          data-test="div-delivery-change"
          class="row"
        >
          <div class="col-12 d-flex">
            <div class="form-group col-4">
              <label class="form-label">
                New delivery date <span class="text-danger">*</span>
              </label>
              <div class="input-group">
                <input
                  v-model="newDeliveryDate"
                  data-test="txt-delivery-date"
                  :min="deliveryDate"
                  :max="
                    moment(deliveryDate, 'YYYY-MM-DD')
                      .add(1, 'week')
                      .format('YYYY-MM-DD')
                  "
                  type="date"
                  class="form-control"
                  required
                  @change="handleDateChange"
                />
                <div class="input-group-append">
                  <span class="input-group-text">{{moment(newDeliveryDate).format("ddd")}}</span>
                </div>
              </div>
            </div>
            <div class="form-group col-4">
              <label class="form-label">
                New delivery time <span class="text-danger">*</span>
              </label>
              <select
                v-model="newDeliveryTime"
                data-test="select-delivery-time"
                class="form-control custom-select"
                required
              >
                <option :value="null" disabled>Select time</option>
                <option
                  v-for="value in deliveryTimes"
                  :key="value.id"
                  :value="value"
                >
                  {{value.name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <!-- Instruction -->
        <div v-if="instruction" class="row">
          <div class="col-7">
            <div class="form-group">
              <label class="form-label">
                Instruction (auto) <span class="text-danger">*</span>
              </label>
              <textarea
                v-model="instruction"
                class="form-control"
                data-test="txt-instruction"
                required
                rows="2"
              ></textarea>
            </div>
          </div>
        </div>

        <div v-if="this.changeType === OrderChangeRequestTypes.Other.key" class="row">
          <div class="col-7">
            <div class="form-group">
              <label class="form-label">
                Instruction <span class="text-danger">*</span>
              </label>
              <textarea
                v-model="otherInstruction"
                class="form-control"
                data-test="txt-other-instruction"
                required
                rows="2"
              ></textarea>
            </div>
          </div>
        </div>

        <!-- Comments -->
        <div class="row">
          <div class="col-7">
            <div class="form-group">
              <label class="form-label">Comment (optional)</label>
              <textarea
                v-model="comment"
                class="form-control"
                data-test="txt-comment"
                rows="5"
              ></textarea>
            </div>
          </div>
        </div>
      </dimmer>
    </form>

    <template slot="modal-footer">
      <span>
        <!-- empty spacer -->
      </span>
      <button
        class="btn btn-primary"
        data-test="btn-submit"
        type="submit"
        :disabled="loading"
        form="changeRequestForm"
      >
        Submit
      </button>
    </template>
  </b-modal>
</template>

<script>
import OrderChangeRequestTypes from '@/assets/enums/OrderChangeRequestTypes';
import {orderChangeRequest} from '@/services';
import {captureException} from '@sentry/vue';
import moment from 'moment-timezone';

export default {
  name: 'OrderChangeRequestModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    deliveryDate: {
      type: String,
      required: true,
    },
    deliveryTime: {
      type: Object,
      required: true,
    },
    deliveryDays: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      OrderChangeRequestTypes,
      loading: false,
      changeType: '',
      comment: '',
      newDeliveryDate: this.deliveryDate,
      newDeliveryTime: this.deliveryTime,
      otherInstruction: '',
    };
  },
  computed: {
    safeShow: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.resetData();
        this.$emit('update:show', newValue);
      },
    },
    deliveryDay() {
      return this.newDeliveryDate
        ? this.deliveryDays.find(
          x => x.name === moment(this.newDeliveryDate).format('dddd'),
        )
        : null;
    },
    deliveryTimes() {
      return this.deliveryDay
        ? this.deliveryDays.find(x => x.id === this.deliveryDay.id).times
        : [];
    },
    instruction() {
      if (this.changeType === OrderChangeRequestTypes.Delivery.key) {
        const date = moment(this.newDeliveryDate, 'YYYY-MM-DD');
        return `This order delivery is changed to ${date?.format('dddd, DD MMM YYYY') ?? '{date}'}, ${this.newDeliveryTime?.name ?? '{time}'}.`;
      }
      return '';
    },
  },
  methods: {
    resetData() {
      this.changeType = '';
      this.comment = '';
      this.newDeliveryDate = this.deliveryDate;
      this.newDeliveryTime = this.deliveryTime;
      this.otherInstruction = '';
    },
    handleDateChange() {
      // Reset delivery time when changing delivery date
      this.newDeliveryTime = null;
    },
    async submit() {
      let change = null;
      let description = this.instruction;

      switch (this.changeType) {
      case OrderChangeRequestTypes.Delivery.key:
        if (this.deliveryDate === this.newDeliveryDate && this.deliveryTime === this.newDeliveryTime) {
          alert('Delivery date and time is the same, please select a different date/time.');
          return;
        }
        change = {
          delivery_date: {
            old: this.deliveryDate,
            new: this.newDeliveryDate,
          },
          delivery_time: {
            old: this.deliveryTime.name,
            new: this.newDeliveryTime.name,
          },
        };
        break;
      case OrderChangeRequestTypes.Other.key:
        description = this.otherInstruction;
      }

      this.loading = true;
      try {
        await orderChangeRequest.save({
          order_id: this.$route.params.id,
          type: this.changeType,
          comment: this.comment,
          change,
          description,
        });

        this.resetData();
        this.$emit('submit');
        this.$toasted.success('Change request submitted successfully');
      }
      catch (error) {
        console.error(error);
        captureException(error);

        if (error.response?.status === 422 && error.response?.data.message) {
          this.$toasted.error(error.response.data.message);
          return;
        }

        this.$toasted.error('Error submitting change request');
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>
